import { Link } from 'react-router-dom';

import { getLangText } from 'utils/ui';

import Title from 'components/title';
import Split from 'components/split-page';
import Button from 'components/button';
import Progress from 'components/unit-progress';
import SiteCard from 'components/site-detail/site-card';
import UnitTypeList from 'components/site-detail/unit-type-list';

function GroupButton({
  isActive = false,
  children,
  ...rest
}) {
  return (
    <Button
      variant={ isActive ? 'primary' : 'secondary' }
      size="sm"
      style={{
        margin: '0 .5rem .5rem 0',
        flexShrink: 0,
      }}
      {...rest}
    >
      {children}
    </Button>
  );
}


export default function SiteDetailMain({
  cms,
  site,
  unitTypes,
  unitTypesInfiniteQuery,
  groupCode,
}) {
  return (
    <>
      <Progress site={site} />

      <Split className="mb-2">
        <div className="flex-1"><SiteCard site={site} showFeatures /></div>
        <div className="flex-1 px-2 px-sm-0 mt-lg-2" style={{ minWidth: 0 }}>
          <Title cms={cms.block('types')} />

          {site.unitTypeGroups.length > 0 && (
            <div className="d-flex" style={{ overflowX: 'auto' }}>
              <GroupButton
                as={Link} to={``}
                isActive={groupCode === null}
              >
                <cms.Text id="unitTypeGroups.all" />
              </GroupButton>

              {site.unitTypeGroups.map(unitTypeGroup => (
                <GroupButton
                  key={unitTypeGroup.id}
                  as={Link} to={`?group=${unitTypeGroup.code}`}
                  isActive={groupCode === unitTypeGroup.code}
                >
                  {getLangText(unitTypeGroup.title)}
                </GroupButton>
              ))}
            </div>
          )}

          <UnitTypeList
            cms={cms}
            site={site}
            unitTypes={unitTypes}
            infiniteQuery={unitTypesInfiniteQuery}
          />
        </div>
      </Split>
    </>
  );
}
